import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import Grid from "@cx/ui/Grid";
import Row from "@cx/ui/Row";
import Col from "@cx/ui/Col";
import Popover from "@cx/ui/Popover";
import ButtonToolbar from "react-bootstrap/lib/ButtonToolbar";
import Button from "@cx/ui/Button";
// import IconInfoOutline from "@cx/ui/Icons/IconInfoOutline";
import LoadingIndicator from "@cx/ui/LoadingIndicator";
import SelectInput from "@cx/ui/SelectInput";
import TextInput from "@cx/ui/TextInput";
import VehicleGroupSelector from "../../../../../reusable/VehicleGroups/VehicleGroupSelector";
import Confirmation from "../../../../../../commonUtil/dialog/Confirmation";
import SimpleModalDialog from "../../../../../../commonUtil/dialog/SimpleModalDialog";
import { data } from "../../data/acuraOverrides.json";
import { PropTypes } from "prop-types";
import { toast } from "@cx/ui/Toast";
import Tooltip from "@cx/ui/Tooltip";
import { OperationContext } from "../../../operation-context";
import {
  makeSecureRestApi
  // showBodyMask,
  // hideBodyMask
} from "../../../../../../api/xmmAxios";
import {
  isSameValue,
  isDifferentValue,
  toEmptyStringIfUndefined
} from "../../../../../../commonUtil/utils/string";
import CustomLoadingOverlay from "../../../../../../commonUtil/components/loadingmask/CustomLoadingOverlay";
import OpcodeEditor from "../../../../../../commonUtil/editors/OpcodeEditor";
import {
  PriceOverridePositions,
  MVC_ALL_VEHICLES,
  MVC_CHOOSE_VEHICLES
} from "../../../../../../constants/ModuleConstants";
import VehicleGroupEditor from "../../../../../reusable/editors/VehicleGroupEditor";
import LaborTimeEditor from "../../../../../../commonUtil/editors/LaborTimeEditor";
import PriceEditor from "../../../../../../commonUtil/editors/PriceEditor";
import {
  extractLaborRates,
  extractLaborRateValues,
  priceFormatter
} from "../../../../../../commonUtil/utils/formatter";
import { laborTimeSetter } from "../../../../../../commonUtil/utils/valuesetter";
import {
  encodeNullValues,
  convertFromMinutesToHours,
  doesEmpty
} from "../../../../../../commonUtil/utils/object";
import CreateVehicleGroupModal from "../../../../../reusable/VehicleGroups/CreateVehicleGroupModal";
import ManageVehicleGroupModal from "../../../../../reusable/VehicleGroups/ManageVehicleGroupModal";
import {
  MAX_VEHICLE_GROUP_NAME_LENGTH,
  findMakeVehicleGroupMap,
  removeItemFromMap,
  createVehicleGroupOptions,
  getGroupNameList
} from "../../../../../reusable/VehicleGroups/VehicleGroupUtil";
import { getAllVehicleGroupName } from "../../../../../../commonUtil/utils/menu";
import {
  convertToFloat,
  isTrue,
  toFloat
} from "../../../../../../commonUtil/utils/value";
import IconError from "@cx/ui/Icons/IconError";
import IconWarning from "@cx/ui/Icons/IconWarning";
import IconInfoOutline from "@cx/ui/Icons/IconInfoOutline";
import StatusBox from "../../../../../../commonUtil/components/templates/StatusBox";
import { xlate } from "../../../../../../commonUtil/i18n/locales";
import * as gtmEvents from "../../../../../utils/gtag-eventlist";
import ModalDialog from "@cx/ui/ModalDialog";
import AddPartsModal from "../../../../../../previewGrids/components/modal/AddPartsModal";
import DirtyCheckPopup from "../../../../../reusable/modals/DirtyCheckPopup";
// import generateApiToken from "../../../../../../commonUtil/utils/apiTokenValidator";
/* eslint-disable no-console */
class OverridesGrid extends Component {
  static contextType = OperationContext;
  constructor(props, context) {
    super(props);

    this.createVehGpModalRef = React.createRef();
    this.manageVehGpModalRef = React.createRef();
    this.vehicleGroupSelectorRef = React.createRef();

    this.onChange = this.onChange.bind(this);
    this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
    this.onExpandSlider = this.onExpandSlider.bind(this);
    this.onGetPartsPricing = this.onGetPartsPricing.bind(this);
    this.getRowNodeId = this.getRowNodeId.bind(this);
    this.loadDealerLaborRules = this.loadDealerLaborRules.bind(this);
    this.handleColumnResized = this.handleColumnResized.bind(this);
    this.onCellValueChanged = this.onCellValueChanged.bind(this);
    this.onSearchBoxChanged = this.onSearchBoxChanged.bind(this);
    this.openDeleteRulesModal = this.openDeleteRulesModal.bind(this);
    this.onVehicleGroupBlur = this.onVehicleGroupBlur.bind(this);
    this.onVehicleGroupChange = this.onVehicleGroupChange.bind(this);
    this.closeDeleteRulesModal = this.closeDeleteRulesModal.bind(this);
    this.handleAddRule = this.handleAddRule.bind(this);
    this.handleDeleteRules = this.handleDeleteRules.bind(this);
    this.handleSelectionChanged = this.handleSelectionChanged.bind(this);
    this.refreshGrid = this.refreshGrid.bind(this);
    this.vehicleCellRenderer = this.vehicleCellRenderer.bind(this);
    this.nameCellRenderer = this.nameCellRenderer.bind(this);
    // testing vehicle count popover
    this.updateInput = this.updateInput.bind(this);
    this.openManageVehicleGroupsFromEditor =
      this.openManageVehicleGroupsFromEditor.bind(this);
    this.openCreateVehicleGroupFromEditor =
      this.openCreateVehicleGroupFromEditor.bind(this);
    this.onSetNamedVehicleFilter = this.onSetNamedVehicleFilter.bind(this);
    this.onRemoveVehicleGroupFromList =
      this.onRemoveVehicleGroupFromList.bind(this);
    this.proceedUncheck = this.proceedUncheck.bind(this);
    this.cancelUncheck = this.cancelUncheck.bind(this);
    this.onMakeChange = this.onMakeChange.bind(this);
    this.addParts = this.addParts.bind(this);
    this.updateParts = this.updateParts.bind(this);
    this.deleteParts = this.deleteParts.bind(this);

    const { operation } = props;
    const { make, globalRepairOpFlag, serviceKind } = operation;
    const {
      localeStrings,
      makeVariantMap,
      dealerLaborRateCodes,
      makeRateCodesMap,
      partManufacturerCodeMap,
      enableDMSPlusContent
    } = context.appContext;
    const { pricingMethod } = makeVariantMap[make];
    this.initializeLocaleValues();
    const metaVehicleFilterName = getAllVehicleGroupName(
      make,
      this.allVehiclesTmpl
    );

    const isDMSPlusEnabled = enableDMSPlusContent;

    this.state = {
      // component state props
      isDMSPlusEnabled,
      partsOverridesModified: false,
      showPartsGridSpeedBump: false,
      clickedRule: {},
      partManufacturerCodeMap,
      defaultPartManufacturerCode: "",
      partsList: [],
      showPartsGrid: false,
      globalRepairOpFlag,
      make,
      vehicleSelectorMake: make,
      dealerLaborRateCodes,
      serviceKind,
      makeRateCodesMap,
      checked: false, // true if different prices for package and a la carte pricing
      laborRules: [],
      operation,
      packagePricing: true, // true if package and a la carted pricing prices have same values
      pricingMethod, // 0: no pricing, 1: calculated, 3: flat
      selectionlist: [],
      services: data,
      metaVehicleScope: MVC_ALL_VEHICLES,
      metaVehicleFilterName,
      metaVehicleFilterId: "",
      overrideName: "",
      groupName: "",
      showMask: false,
      // ag-grid state props
      columnDefs: [], // this.getColumnDefs(context.appContext, pricingMethod, false),
      components: {
        badgeCellRenderer
      },
      // frameworkComponents: {
      //   laborTimeEditor: LaborTimeEditor,
      //   opcodeEditor: OpcodeEditor,
      //   priceEditor: PriceEditor,
      //   vehicleGroupEditor: VehicleGroupEditor,
      //   customLoadingOverlay: CustomLoadingOverlay,
      //   customNoRowsOverlay: CustomLoadingOverlay
      // },
      loadingOverlayComponent: CustomLoadingOverlay,
      loadingOverlayComponentParams: {
        loadingMessage: "Loading",
        isLoading: true,
        noRows: false
      },
      noRowsOverlayComponent: CustomLoadingOverlay,
      noRowsOverlayComponentParams: {
        loadingMessage: "No records found.",
        isLoading: false,
        noRows: true
      },
      // Note: Set locale strings in this localeText {} for ag-grid controls
      localeText: {
        filteredRows: localeStrings["xmm.portal.ag_grid.filteredRows"],
        selectedRows: localeStrings["xmm.portal.ag_grid.selectedRows"],
        totalRows: localeStrings["xmm.portal.ag_grid.totalRows"],
        totalAndFilteredRows:
          localeStrings["xmm.portal.ag_grid.totalAndFilteredRows"],
        noRowsToShow: localeStrings["xmm.portal.ag_grid.noRowsToShow"]
      },
      defaultColDef: {
        sortable: true,
        resizable: true,
        enableRowGroup: false,
        sortingOrder: ["asc", "desc", null],
        autoHeight: true,
        filter: true,
        rowGroup: false,
        width: 100,
        suppressMenu: true,
        editable(params) {
          return !isTrue(params.data.externallyManaged);
        },
        cellEditorSelector(params) {
          const { colDef } = params;
          let component = null;
          const { field } = colDef;
          switch (field) {
            case "description":
            case "position":
              return {
                component: "agRichSelectCellEditor",
                params: { values: Object.keys(PriceOverridePositions) }
              };
            case "vehicleCount":
              component = VehicleGroupEditor;
              break;
            case "dmsOpcode":
              component = OpcodeEditor;
              break;
            case "dealerLaborRateCodeId":
              component = "agRichSelectCellEditor";
              break;
            case "scheduledLaborTimeInHours":
            case "unscheduledLaborTimeInHours":
              component = LaborTimeEditor;
              break;
            case "overridePrice":
            case "priceAlacarte":
            case "partsPrice":
            case "partsPriceAlacarte":
            case "totalPrice":
            case "totalPriceAlacarte":
              component = PriceEditor;
              break;
            default:
              break;
          }
          return { component };
        },
        cellClassRules: this.getCellClassRule(),
        cellClass: "editable-cell" // this.getCellClass
      },
      multiSortKey: "ctrl",
      sortingOrder: ["asc", "desc", null],
      rowData: [],
      rowSelection: "multiple", // allows multiple row selections
      isRowSelectable() {
        return true; // to see checkbox
      },
      // true - use browser default tooltip instead of ag-grid tooltip
      enableBrowserTooltips: true,
      columnTypes: {
        nonEditableColumn: { editable: false },
        noFilterColumn: {
          width: 100,
          columnGroupShow: "open",
          filter: false
        },
        actionColumn: {
          filter: false,
          editable: false,
          sortable: false,
          suppressMenu: true,
          enableRowGroup: false
        }
      },
      onColumnMoved: this.refreshGrid,
      onColumnPinned: this.refreshGrid,
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent", align: "left" }
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
            toolPanelParams: {
              suppressPivots: true,
              suppressPivotMode: true,
              suppressValues: true,
              suppressRowGroups: true
            }
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
          }
        ],
        hiddenByDefault: false,
        dinamycDefaultPayTypeOptions: []
      }
    };
  }

  componentDidMount() {
    window.addEventListener(
      "openManageVehicleGroupsFromEditor",
      this.openManageVehicleGroupsFromEditor,
      false
    );
    window.addEventListener(
      "openCreateVehicleGroupFromEditor",
      this.openCreateVehicleGroupFromEditor,
      false
    );
    window.addEventListener(
      "dealerNameFilterEvent",
      this.onDealerNameFilterEvent,
      false
    );
    window.addEventListener(
      "removeVehicleGroupFromList",
      this.onRemoveVehicleGroupFromList,
      false
    );
    window.addEventListener(
      "closeCreateVehicleModalEvent",
      this.onCloseCreateVehicleGroup,
      false
    );
    window.addEventListener(
      "setNamedVehicleFilter",
      this.onSetNamedVehicleFilter,
      false
    );
    window.addEventListener("expandSlider", this.onExpandSlider, false);
    this.getServiceType();
    this.getPayTypeList();
  }

  componentWillUnmount() {
    window.removeEventListener(
      "openManageVehicleGroupsFromEditor",
      this.openManageVehicleGroupsFromEditor,
      false
    );
    window.removeEventListener(
      "openCreateVehicleGroupFromEditor",
      this.openCreateVehicleGroupFromEditor,
      false
    );
    window.removeEventListener(
      "dealerNameFilterEvent",
      this.onDealerNameFilterEvent,
      false
    );
    window.removeEventListener(
      "removeVehicleGroupFromList",
      this.onRemoveVehicleGroupFromList,
      false
    );
    window.removeEventListener(
      "closeCreateVehicleModalEvent",
      this.onCloseCreateVehicleGroup,
      false
    );
    window.removeEventListener(
      "setNamedVehicleFilter",
      this.onSetNamedVehicleFilter,
      false
    );
    window.removeEventListener("expandSlider", this.onExpandSlider, false);
  }
  initializeLocaleValues() {
    this.addOverrideLabel = xlate("xmm.portal.common.add_override_lbl");
    this.setDiffPriceLabel = xlate(
      "xmm.portal.operations.tab.pricing_opcodes.different_prices"
    );
    this.overridesLabel = xlate(
      "xmm.portal.operations.tab.pricing_opcodes.overrides"
    );
    this.searchLabel = xlate("xmm.portal.common.search_label");
    this.deleteButtonLabel = xlate("xmm.portal.common.delete_button");
    this.vehicleGroupLabel = xlate("xmm.portal.common.vehicle_group_lbl");
    this.descriptionLabel = xlate("xmm.portal.common.description_lbl");
    this.continueToSetLabel = xlate(
      "xmm.portal.operations.tab.pricing_opcodes.continue_to_set_details"
    );
    this.allVehiclesTmpl = xlate("xmm.portal.common.all_make_vehicles");
  }
  getCellClass(params) {
    const { field } = params.colDef;
    if (validationFields.includes(field)) {
      const { data } = params;
      if (data) {
        const { errors } = data;
        if (errors && errors[field]) {
          return "editable-cell xmm-grid-cell-error";
        }
      }
    }
    return "editable-cell";
  }
  // write common cell field validation here
  getCellClassRule() {
    const cellClassRules = {
      "editable-disabled-cell"(params) {
        const { data } = params;
        if (data) {
          const { field } = params.colDef;
          const { externallyManaged, globalRepairOpFlag, make } = data;
          if (
            isTrue(externallyManaged) ||
            field === "make" ||
            (!globalRepairOpFlag && field === "name" && make === "ANY") ||
            (field === "vehicleCount" && make === "ANY")
          ) {
            return true;
          }
        }
      },
      "editable-caret-cell"(params) {
        const { data } = params;
        if (data) {
          const { externallyManaged } = data;
          const { field } = params.colDef;
          if (selectableFields.includes(field) && !isTrue(externallyManaged)) {
            return true;
          }
        }
      },
      "editable-cell"(params) {
        const { field } = params.colDef;
        if (validationFields.includes(field)) {
          const { data } = params;
          if (data) {
            const { errors } = data;
            if (!errors || (errors && !errors[field])) {
              return true;
            }
          }
        }
      },
      "xmm-grid-price"(params) {
        const { field } = params.colDef;
        if (rightAlignedFields && rightAlignedFields.includes(field)) {
          return true;
        }
      },
      "xmm-grid-cell-error"(params) {
        const { field } = params.colDef;
        if (validationFields.includes(field)) {
          const { data } = params;
          if (data) {
            const { errors } = data;
            if (errors && errors[field]) {
              return true;
            }
          }
        }
      }
    };
    return cellClassRules;
  }
  getServiceType = () => {
    const { dealerCode } = this.context;
    makeSecureRestApi(
      {
        url: "/ops/proxyapi/ddsproxy/rest/table/dealerServiceType",
        method: "get",
        data: {},
        params: {
          dealerCode
        }
      },
      data => {
        if (data && data.length) {
          const dataList = data.map(item => {
            return { value: item.serviceTypeCode, label: item.description };
          });
          this.setState({ serviceTypeList: dataList });
        }
      }
    );
  };

  getPayTypeList() {
    if (this.state.isDMSPlusEnabled) {
      const { dealerCode, loadOperation } = this.context;
      const { make } = loadOperation;
      const restUrl = `ops/proxyapi/oeproxy/rest/internal/xmmdealer/getDealerPayTypesByDealerType/${dealerCode}`;
      makeSecureRestApi(
        {
          url: restUrl,
          method: "get"
        },
        data => {
          let filter = [];
          if (data && data.length) {
            filter = data
              .filter(item =>
                make === "ANY" || make === "ALL MAKES"
                  ? item.make === "ALL MAKES"
                  : item.make === make || item.make === "ALL MAKES"
              )
              .map(item => {
                return {
                  value: item.description,
                  label: item.description
                };
              });
            this.setState(
              {
                dinamycDefaultPayTypeOptions: filter
              },
              () => {
                this.updateGridView(false);
              }
            );
          }

          if (!filter.length) {
            const msg = this.emptyPayType;
            this.updateStatusBox(msg, "error", false, true);
            toast.error(msg);
          }
        },
        error => {
          const msg = error["message"]
            ? error.message
            : "There was an error while fetching data for this dealer.  Please try again later.";
          this.updateStatusBox(msg, "error", false, true);
        }
      );
    }
  }

  getBaseColumnDefs(context) {
    const {
      globalRepairOpFlag,
      make,
      pricingMethod,
      isDMSPlusEnabled,
      serviceTypeList,
      dinamycDefaultPayTypeOptions
    } = this.state;
    const { dealer, localeStrings } = context;
    const { commonOpsOverridePartsSetting } = dealer;
    const commonOpsOverridePartsSettingEnabled = commonOpsOverridePartsSetting;
    const hidePartsColumn =
      !commonOpsOverridePartsSettingEnabled ||
      (pricingMethod !== 1 && !globalRepairOpFlag);
    const showDmsColumns = isDMSPlusEnabled;

    return [
      {
        headerName: "",
        // headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: params => {
          const { data } = params;
          return data && !isTrue(data.externallyManaged);
        },
        type: "actionColumn",
        pinned: "left",
        field: "checked",
        suppressSizeToFit: true,
        suppressColumnsToolPanel: true,
        suppressFiltersToolPanel: true,
        maxWidth: 40,
        minWidth: 40,
        width: 40
      },
      {
        headerName: localeStrings["xmm.portal.grid.make"],
        field: "make",
        editable: false,
        cellClass: "editable-disabled-cell",
        sortingOrder: ["asc", "desc"],
        hide: make !== "ANY",
        suppressColumnsToolPanel: make !== "ANY",
        suppressFiltersToolPanel: make !== "ANY",
        maxWidth: 140,
        minWidth: 100
        // width: 40
      },
      {
        headerName: localeStrings["xmm.portal.grid.description"],
        field: "name",
        editable: true,
        sortingOrder: ["asc", "desc"],
        minWidth: 150,
        maxWidth: 400,
        cellClass: "editable-cell",
        // cellClass: "cell-wrap-text", // this is very important to make the cell wrap
        cellRenderer: this.nameCellRenderer
        // cellRenderer(params) {
        //   const name = params.value;
        //   const { errorMsgs, errors } = params.data;
        //   if (hasErrors(errors) && errorMsgs) {
        //     const errorSummary = getErrorMessages(errors, errorMsgs);
        //     return `<span title='${errorSummary}' >${name}<i class='fas fa-exclamation-circle'></i></span>`;
        //   }
        //   return name;
        // }
      },
      {
        headerName: localeStrings["xmm.portal.grid.position"],
        field: "position",
        enableRowGroup: false,
        hide: !globalRepairOpFlag,
        suppressColumnsToolPanel: !globalRepairOpFlag,
        suppressFiltersToolPanel: !globalRepairOpFlag,
        refData: PriceOverridePositions,
        minWidth: 100,
        cellStyle: {
          textAlign: "left"
        },
        filter: "agSetColumnFilter",
        filterParams: { buttons: ["clear"] }
      },
      {
        headerName: localeStrings["xmm.portal.grid.vehicles"],
        field: "vehicleCount",
        editable() {
          return make !== "ANY";
        },
        cellEditorParams: { parentHandle: this },
        cellRenderer: this.vehicleCellRenderer,
        filter: "agTextColumnFilter",
        cellEditorPopup: true,
        cellStyle: {
          textAlign: "center"
        },
        minWidth: 80,
        width: 110
      },
      {
        headerName: localeStrings["xmm.portal.grid.opcode"],
        field: "dmsOpcode",
        // cellClass: "editable-cell",
        cellEditorParams: {
          parentHandle: this
        },
        minWidth: 100,
        filterParams: {
          buttons: ["clear"]
        }
      },
      {
        headerName: localeStrings["xmm.portal.grid.parts"],
        field: "parts",
        editable: false,
        minWidth: 90,
        hide: hidePartsColumn,
        suppressColumnsToolPanel: hidePartsColumn,
        suppressFiltersToolPanel: hidePartsColumn,
        cellRenderer: this.partsCellRenderer
      },
      {
        headerName: localeStrings["xmm.portal.grid.service_type"],
        field: "serviceTypeCode",
        minWidth: 90,
        hide: !showDmsColumns || hidePartsColumn,
        cellEditorPopup: true,
        cellClass: "editable-caret-cell",        
        suppressColumnsToolPanel: !showDmsColumns || hidePartsColumn,
        suppressFiltersToolPanel: !showDmsColumns || hidePartsColumn,
        cellEditorSelector(params) {
          return {
            component: "agRichSelectCellEditor",
            params: {
              values: extractValues(serviceTypeList)
            }
          };
        }
      },
      {
        headerName: localeStrings["xmm.portal.grid.pay_type"],
        field: "payType",
        minWidth: 90,
        hide: !showDmsColumns || hidePartsColumn,
        cellEditorPopup: true,
        cellClass: "editable-caret-cell",        
        suppressColumnsToolPanel: !showDmsColumns || hidePartsColumn,
        suppressFiltersToolPanel: !showDmsColumns || hidePartsColumn,
        cellEditorSelector(params) {
          return {
            component: "agRichSelectCellEditor",
            params: {
              values: extractValues(dinamycDefaultPayTypeOptions)
            }
          };
        }
      },
      {
        headerName: localeStrings["xmm.portal.grid.skill_level"],
        field: "skillLevel",
        minWidth: 90,
        hide: !showDmsColumns || hidePartsColumn,
        cellEditorPopup: true,
        cellClass: "editable-caret-cell",
        suppressColumnsToolPanel: !showDmsColumns || hidePartsColumn,
        suppressFiltersToolPanel: !showDmsColumns || hidePartsColumn,
        cellEditorSelector(params) {
          return {
            component: "agRichSelectCellEditor",
            params: {
              values: ["A", "B", "C", "D"]
            }
          };
        }
      }
    ];
  }

  getPackageTotalColumnDef(localeStrings) {
    // const { localeStrings } = this.context.appContext;
    const { pricingMethod, checked } = this.state;
    const headerName =
      pricingMethod === 1 || !checked
        ? localeStrings["xmm.portal.grid.total_price"]
        : localeStrings["xmm.portal.grid.package_price"];
    return [
      {
        headerName,
        field: "totalPrice",
        cellClass: "editable-cell",
        cellEditorParams: {
          keepInvalidValue: true
        },
        minWidth: 60,
        cellStyle: {
          textAlign: "right"
        },
        valueFormatter: priceFormatter
        // valueSetter: priceValueSetter
      }
    ];
  }

  getAlacarteTotalColumnDef(localeStrings) {
    const { pricingMethod } = this.state;
    const headerName =
      pricingMethod === 1
        ? localeStrings["xmm.portal.grid.total_price"]
        : localeStrings["xmm.portal.grid.alacarte_price"];
    return [
      {
        headerName,
        field: "totalPriceAlacarte",
        cellClass: "editable-cell",
        cellEditorParams: {
          keepInvalidValue: true
        },
        minWidth: 60,
        cellStyle: {
          textAlign: "right"
        },
        valueGetter(params) {
          return params.data.totalPriceAlacarte;
        },
        valueFormatter: priceFormatter
        // valueSetter: priceValueSetter
      }
    ];
  }

  getCalculatedColumnDefs(context, checked) {
    const { localeStrings } = context;
    const packageColumnDefs = this.getPackageColumnDefs(context);
    const laborRateColumnDef = this.getLaborRateColumnDefs(context);
    const emptyCol = {
      colId: "1stEmptyCol",
      minWidth: 1,
      maxWidth: 1,
      hide: !checked,
      cellClass: "xmm-group-cell",   
      suppressColumnsToolPanel: !checked,
      suppressFiltersToolPanel: !checked,
    };
    if (checked) {
      const alacarteColumnDefs = this.getAlaCarteColumnDefs(context);
      return laborRateColumnDef.concat([
        emptyCol,
        {
          headerName: localeStrings["xmm.portal.grid.package_pricing"],
          marryChildren: true,
          children: packageColumnDefs
        },
        {
          colId: "2ndEmptyCol",
          minWidth: 1,
          maxWidth: 1,
          cellClass: "xmm-group-cell"
        },
        {
          headerName: localeStrings["xmm.portal.grid.alacarte_pricing"],
          marryChildren: true,
          children: alacarteColumnDefs
        }
      ]);
    }
    return laborRateColumnDef.concat(emptyCol).concat(packageColumnDefs);
  }

  getLaborRateColumnDefs(context) {
    const { dealerLaborRateCodes, localeStrings, makeRateCodesMap } = context;
    const { isDMSPlusEnabled } = this.state;
    const showDmsColumns = isDMSPlusEnabled;
    const locale = this.context.appContext.locale;
    return [
      {
        headerName: localeStrings["xmm.portal.grid.labor_rate"],
        field: "dealerLaborRateCodeId",
        // cellClass: "editable-caret-cell",
        enableRowGroup: false,
        cellEditorPopup: true,
        cellEditorParams(params) {
          const make = params.data.make;
          const values = extractLaborRateValues(makeRateCodesMap, make);
          return { values };
        },
        hide: showDmsColumns,
        refData: extractLaborRates(dealerLaborRateCodes),
        minWidth: locale === "fr_CA" ? 100 : 60,
        cellStyle: {
          textAlign: "left"
        },
        filter: "agSetColumnFilter",
        filterParams: { buttons: ["clear"] }
      }
    ];
  }

  getPackageColumnDefs(context) {
    const { localeStrings } = context;
    const locale = this.context.appContext.locale;
    return [
      // Package Pricing cols
      {
        headerName: localeStrings["xmm.portal.grid.labor_price"],
        field: "overridePrice",
        cellClass: "editable-cell",
        cellEditorParams: {
          keepInvalidValue: true
        },
        minWidth: locale === "fr_CA" ? 70 : 60,
        cellStyle: {
          textAlign: "right"
        },
        valueGetter(params) {
          return params.data.overridePrice;
        },
        valueFormatter: priceFormatter
      },
      {
        headerName: localeStrings["xmm.portal.grid.labor_time"],
        field: "scheduledLaborTimeInHours",
        // cellClass: "editable-cell",
        minWidth: locale === "fr_CA" ? 80 : 60,
        cellStyle: {
          textAlign: "right"
        },
        valueSetter: laborTimeSetter
      },
      {
        headerName: localeStrings["xmm.portal.grid.parts_price"],
        field: "partsPrice",
        cellClass: "editable-cell",
        cellEditorParams: {
          keepInvalidValue: true
        },
        minWidth: locale === "fr_CA" ? 70 : 60,
        cellStyle: {
          textAlign: "right"
        },
        valueGetter(params) {
          return params.data.partsPrice;
        },
        valueFormatter: priceFormatter
        // valueSetter: priceValueSetter
      }
    ].concat(this.getPackageTotalColumnDef(localeStrings));
  }

  getAlaCarteColumnDefs(context) {
    const { localeStrings } = context;
    return [
      {
        headerName: localeStrings["xmm.portal.grid.labor_price"],
        field: "priceAlacarte",
        cellClass: "editable-cell",
        cellEditorParams: {
          keepInvalidValue: true
        },
        minWidth: 60,
        cellStyle: {
          color: "black",
          textAlign: "right"
        },
        valueGetter(params) {
          return params.data.priceAlacarte;
        },
        valueFormatter: priceFormatter
        // valueSetter: priceValueSetter
      },
      {
        headerName: localeStrings["xmm.portal.grid.labor_time"],
        field: "unscheduledLaborTimeInHours",
        // cellClass: "editable-cell",
        minWidth: 60,
        cellStyle: {
          textAlign: "right"
        },
        valueSetter: laborTimeSetter
      },
      {
        headerName: localeStrings["xmm.portal.grid.parts_price"],
        field: "partsPriceAlacarte",
        cellClass: "editable-cell",
        cellEditorParams: {
          keepInvalidValue: true
        },
        minWidth: 60,
        cellStyle: {
          textAlign: "right"
        },
        valueGetter(params) {
          return params.data.partsPriceAlacarte;
        },
        valueFormatter: priceFormatter
        // valueSetter: priceValueSetter
      }
    ].concat(this.getAlacarteTotalColumnDef(localeStrings));
  }

  getFlatPricingColumnDefs(context, checked) {
    const { localeStrings } = context;
    if (!checked) {
      return this.getPackageTotalColumnDef(localeStrings);
    }
    return []
      .concat(this.getPackageTotalColumnDef(localeStrings))
      .concat(this.getAlacarteTotalColumnDef(localeStrings));
  }

  getColumnDefs(context, pricingMethod, checked) {
    const colDefs = [].concat(this.getBaseColumnDefs(context));
    switch (pricingMethod) {
      case 1:
        return colDefs.concat(this.getCalculatedColumnDefs(context, checked));
      case 2:
        return colDefs.concat(this.getFlatPricingColumnDefs(context, checked));
      case 0:
      default:
        return colDefs;
    }
  }

  updateGridView = checked => {
    const { columnDefs } = this.state;
    if (columnDefs.length === 0 || checked !== this.state.checked) {
      this.setState({ checked }, () => {
        this.updateColumnDefs(checked);
      });
    }
  };

  onChangeCheckbox = event => {
    const { checked } = event.target;
    const { packagePricing } = this.state;
    if (!checked) {
      // uncheck case: go to package pricing
      if (!packagePricing) {
        this.setState({
          showUncheckModal: true,
          packagePricing: !checked
        });
      }
    } else {
      this.setState({ packagePricing: !checked });
      this.updateGridView(checked);
    }
  };

  onExpandSlider = () => {
    this.sizeToFit();
  };

  getRowNodeId(data) {
    return data.dealerOperationRuleId;
  }

  refreshGrid(params) {
    params.api.refreshCells({ force: true });
  }
  convertLaborTimeFromMinutesToHours(rule) {
    rule.unscheduledLaborTimeInHours = convertFromMinutesToHours(
      rule.unscheduledLaborTime
    );
    rule.scheduledLaborTimeInHours = convertFromMinutesToHours(
      rule.scheduledLaborTime
    );
  }
  hasNonBlankFields(validationFields) {
    for (let index = 0; index < validationFields.length; index++) {
      if (!doesEmpty(validationFields[index])) {
        return true;
      }
    }
    return false;
  }
  checkForErrors(rule) {
    const {
      scheduledLaborTime,
      unscheduledLaborTime,
      overridePrice,
      priceAlacarte,
      partsPrice,
      partsPriceAlacarte,
      totalPrice,
      totalPriceAlacarte
    } = rule;
    const { pricingMethod } = this.state;
    let hasWarning = false;
    if (pricingMethod === 0) {
      // No pricing - all fields except opcode should be blank
      const validationFields = [
        scheduledLaborTime,
        unscheduledLaborTime,
        overridePrice,
        priceAlacarte,
        partsPrice,
        partsPriceAlacarte,
        totalPrice,
        totalPriceAlacarte
      ];
      if (this.hasNonBlankFields(validationFields)) {
        hasWarning = true;
      }
    } else if (pricingMethod === 2) {
      // Flat pricing
      const validationFields = [
        scheduledLaborTime,
        unscheduledLaborTime,
        overridePrice,
        priceAlacarte,
        partsPrice,
        partsPriceAlacarte
      ];
      if (this.hasNonBlankFields(validationFields)) {
        hasWarning = true;
      }
    }
    if (hasWarning) {
      const { localeStrings } = this.context.appContext;
      rule.warningMessage =
        localeStrings["xmm.portal.operations.pricing_overrides.warning"];
    }
  }
  isPackagePricing(rule) {
    const {
      scheduledLaborTimeInHours,
      unscheduledLaborTimeInHours,
      overridePrice,
      priceAlacarte,
      partsPrice,
      partsPriceAlacarte,
      totalPrice,
      totalPriceAlacarte
    } = rule;

    return (
      isSameValue(scheduledLaborTimeInHours, unscheduledLaborTimeInHours) &&
      isSameValue(overridePrice, priceAlacarte) &&
      isSameValue(partsPrice, partsPriceAlacarte) &&
      isSameValue(totalPrice, totalPriceAlacarte)
    );
  }
  makePackagePricing(rule) {
    const {
      scheduledLaborTime,
      scheduledLaborTimeInHours,
      overridePrice,
      partsPrice,
      totalPrice
    } = rule;
    rule.unscheduledLaborTime = scheduledLaborTime;
    rule.unscheduledLaborTimeInHours = scheduledLaborTimeInHours;
    rule.priceAlacarte = overridePrice;
    rule.partsPriceAlacarte = partsPrice;
    rule.totalPriceAlacarte = totalPrice;
  }
  loadDealerLaborRules = updateMode => {
    this.showLoadingMask(true);
    const { globalRepairOpFlag } = this.state;
    if (globalRepairOpFlag) {
      this.loadDealerLaborRulesForGlobalRepairOp(updateMode);
    } else {
      this.loadDealerLaborRulesForMaintenanceOp(updateMode);
    }
  };
  loadDealerLaborRulesForGlobalRepairOp = updateMode => {
    const method = "get";
    const { dealerCode } = this.context.appContext;
    const { serviceId } = this.props.operation;
    const params = { dealerCode, operationId: ~~serviceId.substring(1) };
    const url = "/ops/rest-db/getValues/pricingOverride";

    // showBodyMask();
    this.gridApi && this.gridApi.showLoadingOverlay();
    makeSecureRestApi(
      {
        url,
        method,
        params
      },
      data => {
        const dataList = !data || !Array.isArray(data) ? [] : data;
        // console.log(dataList);
        // let { packagePricing } = this.state;
        if (Array.isArray(dataList)) {
          if (!updateMode) {
            const hasLaborRules = dataList.length !== 0;
            const overridesMap = {};
            dataList.forEach(rule => {
              if (rule.metaVehicleFilterId) {
                rule.vehicleCount = "Group";
              }
              this.checkForErrors(rule);
              this.convertLaborTimeFromMinutesToHours(rule);
              // packagePricing = false; // packagePricing && this.isPackagePricing(rule);
              overridesMap[rule.dealerOperationRuleId.toString()] = true;
              rule.globalRepairOpFlag = 1;
            });
            this.setState({
              hasLaborRules,
              overridesMap,
              rowData: dataList,
              laborRules: dataList
            });
          } else {
            const { overridesMap } = this.state;
            const newOverridesMap = {};
            const newDealerOperationRules = dataList.filter(rule => {
              this.convertLaborTimeFromMinutesToHours(rule);
              if (rule.metaVehicleFilterId) {
                rule.vehicleCount = "Group";
              }
              rule.globalRepairOpFlag = 1;
              // no need to recompute packagePricing for add case since user has not enter pricing for new row
              // packagePricing = packagePricing && this.isPackagePricing(rule);
              newOverridesMap[rule.dealerOperationRuleId.toString()] = true;
              return !overridesMap[rule.dealerOperationRuleId.toString()];
            });
            if (newDealerOperationRules.length !== 0) {
              // update
              const res = this.gridApi.applyTransaction({
                add: newDealerOperationRules
              });
              console.log(res, newDealerOperationRules);
            }
            this.setState({
              hasLaborRules: dataList.length !== 0,
              overridesMap: newOverridesMap,
              laborRules: dataList
            });
          }
        }
        // hideBodyMask();
        const { columnDefs } = this.state;
        if (columnDefs.length === 0) {
          this.setState({ packagePricing: false }, () => {
            this.updateGridView(false);
          });
          this.gridApi && this.gridApi.hideOverlay();
        } else {
          setTimeout(() => {
            this.sizeToFit();
            this.gridApi && this.gridApi.hideOverlay();
          }, 0);
        }
        this.showLoadingMask(false);
      },
      error => {
        toast.error(error.message);
        this.gridApi && this.gridApi.showNoRowsOverlay();
        this.showLoadingMask(false);
        // hideBodyMask();
      }
    );
  };
  loadDealerLaborRulesForMaintenanceOp = updateMode => {
    // this.showLoadingMask(true);
    // const { dealerCode, makeVariantMap } = this.context.appContext;
    const { dealerCode } = this.context.appContext;
    const { operation } = this.props;
    const { make, serviceId, variant } = operation;
    // const { pricingMethod } = makeVariantMap[make];
    // const isFlatPricingRule = pricingMethod === 2 ? 1 : 0;
    const params = {
      make,
      variant,
      dealerCode,
      serviceId
      // isFlatPricingRule
    };
    if (make === "ANY") {
      delete params.make;
      delete params.variant;
    }
    const method = "get";
    const url = "/ops/proxyapi/ddsproxy/rest/table/dealerLaborRule";
    // showBodyMask();
    this.gridApi && this.gridApi.showLoadingOverlay();
    makeSecureRestApi(
      {
        url,
        method,
        params
      },
      dataList => {
        console.log(dataList);
        let { packagePricing } = this.state;
        if (dataList) {
          if (!updateMode) {
            const hasLaborRules = dataList.length !== 0;
            const overridesMap = {};
            dataList.forEach(rule => {
              this.checkForErrors(rule);
              this.convertLaborTimeFromMinutesToHours(rule);
              packagePricing = packagePricing && this.isPackagePricing(rule);
              overridesMap[rule.dealerOperationRuleId.toString()] = true;
            });
            this.setState({
              hasLaborRules,
              overridesMap,
              rowData: dataList,
              laborRules: dataList
            });
          } else {
            const { overridesMap } = this.state;
            const newOverridesMap = {};
            const newDealerOperationRules = dataList.filter(rule => {
              this.convertLaborTimeFromMinutesToHours(rule);
              // no need to recompute packagePricing for add case since user has not enter pricing for new row
              // packagePricing = packagePricing && this.isPackagePricing(rule);
              newOverridesMap[rule.dealerOperationRuleId.toString()] = true;
              return !overridesMap[rule.dealerOperationRuleId.toString()];
            });
            if (newDealerOperationRules.length !== 0) {
              // update
              const res = this.gridApi.applyTransaction({
                add: newDealerOperationRules
              });
              console.log(res, newDealerOperationRules);
            }
            this.setState({
              hasLaborRules: dataList.length !== 0,
              overridesMap: newOverridesMap,
              laborRules: dataList
            });
          }
        }
        // hideBodyMask();
        const { columnDefs } = this.state;
        if (columnDefs.length === 0) {
          this.setState({ packagePricing }, () => {
            this.updateGridView(!packagePricing);
          });
          this.gridApi && this.gridApi.hideOverlay();
        } else {
          setTimeout(() => {
            this.sizeToFit();
            this.gridApi && this.gridApi.hideOverlay();
          }, 0);
        }
        this.showLoadingMask(false);
      },
      error => {
        toast.error(error.message);
        this.gridApi && this.gridApi.showNoRowsOverlay();
        this.showLoadingMask(false);
        // hideBodyMask();
      }
    );
  };
  setFieldValidation(dealerLaborRule, field, errorMsg) {
    if (!dealerLaborRule.errors) {
      dealerLaborRule.errors = {};
    }
    dealerLaborRule.errors[field] = true;
    if (errorMsg) {
      this.addFieldErrorMessage(dealerLaborRule, field, errorMsg);
    }
  }
  clearFieldValidation(dealerLaborRule, field) {
    if (dealerLaborRule.errors && dealerLaborRule.errors[field]) {
      dealerLaborRule.errors[field] = false;
      if (dealerLaborRule.errorMsgs && dealerLaborRule.errorMsgs[field]) {
        delete dealerLaborRule.errorMsgs[field];
      }
    }
  }
  clearAllFieldValidations(dealerLaborRule) {
    const { errors } = dealerLaborRule;
    if (errors) {
      const fields = Object.keys(errors);
      fields.forEach(field => {
        this.clearFieldValidation(dealerLaborRule, field);
      });
    }
  }
  addFieldErrorMessage(dealerLaborRule, field, errorMsg) {
    const { errorMsgs } = dealerLaborRule;
    if (!errorMsgs) {
      dealerLaborRule.errorMsgs = {};
    }
    dealerLaborRule.errorMsgs[field] = errorMsg;
  }
  removeFieldErrorMessage(dealerLaborRule, field) {
    const { errorMsgs } = dealerLaborRule;
    if (errorMsgs) {
      dealerLaborRule.errorMsgs[field] = "";
    }
  }
  isValidateField(dealerLaborRule, columnName, field, value) {
    // first clear the error before doing validation
    this.clearFieldValidation(dealerLaborRule, field);

    let errorMsg = null;
    if (
      field === "name" &&
      (!value || value.length > MAX_VEHICLE_GROUP_NAME_LENGTH)
    ) {
      errorMsg =
        "Description must not be blank and cannot exceed 50 characters.";
      this.addFieldErrorMessage(dealerLaborRule, field, errorMsg);
    } else if (
      field === "scheduledLaborTimeInHours" ||
      field === "unscheduledLaborTimeInHours"
    ) {
      const laborTime = toFloat(value);
      if (!isNaN(laborTime) && laborTime > 24.0) {
        errorMsg = `${columnName} cannot exceed 24 hours.`;
        this.addFieldErrorMessage(dealerLaborRule, field, errorMsg);
      }
    } else if (
      field === "overridePrice" ||
      field === "priceAlacarte" ||
      field === "partsPrice" ||
      field === "partsPriceAlacarte" ||
      field === "totalPrice" ||
      field === "totalPriceAlacarte"
    ) {
      const price = toFloat(value);
      if (!isNaN(price) && price > 9999.99) {
        errorMsg = `${columnName} cannot exceed $9,999.99.`;
        this.addFieldErrorMessage(dealerLaborRule, field, errorMsg);
      }
    }
    if (errorMsg) {
      // toast.error(errorMsg);
      this.updateStatusBox(errorMsg, "error", false, true);
      return false;
    }
    return true;
  }
  onCellValueChanged(params) {
    // console.log("onCellValueChanged", params, params.oldValue, params.newValue);
    const { colDef } = params;
    const field = colDef ? colDef.field : null;
    const dealerLaborRule = params.data;
    if (field === "vehicleCount") {
      const { metaVehicleFilterId, outdata } = dealerLaborRule;
      dealerLaborRule.vehicleCount = params.oldValue; // restore vehicleCount
      // params.colDef.field = "metaVehicleFilterId";
      params.oldValue = metaVehicleFilterId;
      params.value = params.newValue = outdata
        ? outdata.metaVehicleFilterId
        : params.oldValue;
      if (
        // params.value &&
        toEmptyStringIfUndefined(params.oldValue) !==
        toEmptyStringIfUndefined(params.newValue)
      ) {
        this.onSaveCellEdit(params, "metaVehicleFilterId");
      } else {
        this.gridApi.applyTransaction({
          update: [dealerLaborRule]
        });
      }
    } else if (
      toEmptyStringIfUndefined(params.oldValue) !==
      toEmptyStringIfUndefined(params.newValue)
    ) {
      const { headerName } = params.colDef;
      if (
        !this.isValidateField(
          dealerLaborRule,
          headerName,
          field,
          params.newValue
        )
      ) {
        this.setFieldValidation(dealerLaborRule, field);
        this.gridApi.applyTransaction({
          update: [dealerLaborRule]
        });
        this.gridApi.refreshCells({ force: true });
      } else {
        this.onSaveCellEdit(params);
      }
    }
  }
  updateFields(dealerLaborRule, field, value) {
    dealerLaborRule[field] = toEmptyStringIfUndefined(value);
    const { checked, pricingMethod } = this.state;
    if (!checked && pricingMethod !== 0) {
      // "scheduledLaborTimeInHours",
      // "unscheduledLaborTimeInHours",
      // "overridePrice",
      // "priceAlacarte",
      // "partsPrice",
      // "partsPriceAlacarte",
      // "totalPrice",
      // "totalPriceAlacarte",
      switch (field) {
        case "scheduledLaborTimeInHours":
          dealerLaborRule["unscheduledLaborTime"] =
            dealerLaborRule["scheduledLaborTime"];
          dealerLaborRule["unscheduledLaborTimeInHours"] =
            dealerLaborRule[field];
          break;
        case "overridePrice":
          dealerLaborRule["priceAlacarte"] = dealerLaborRule[field];
          break;
        case "partsPrice":
          dealerLaborRule["partsPriceAlacarte"] = dealerLaborRule[field];
          break;
        case "totalPrice":
          dealerLaborRule["totalPriceAlacarte"] = dealerLaborRule[field];
          break;
        default:
          break;
      }
    }
  }

  saveRule = (dealerLaborRule, field, callback) => {
    this.updateStatusBox(
      this.context.appContext.localeStrings["xmm.portal.common.saving"],
      "pending",
      false
    );
    const { globalRepairOpFlag } = this.state;
    if (globalRepairOpFlag) {
      this.saveGlobalRepairRule(dealerLaborRule, field, callback);
    } else {
      this.saveMaintenanceRule(dealerLaborRule, field, callback);
    }
  };

  saveGlobalRepairRule = (dealerLaborRule, field, callback) => {
    const { user } = this.context.appContext;
    const { userName } = user;
    const modUser = userName;
    const {
      dealerOperationRuleId,
      // dealerCode,
      // make,
      // serviceId,
      name,
      // metaVehicleFilterId,
      // enabled,
      dealerLaborRateCodeId,
      unscheduledLaborTime,
      // scheduledLaborTime,
      priceAlacarte,
      // overridePrice,
      // partsPrice,
      partsPriceAlacarte,
      // totalPrice,
      totalPriceAlacarte,
      dmsOpcode,
      position,
      replaceParts
    } = dealerLaborRule;
    const data = {
      id: dealerOperationRuleId,
      // dealerOperationRuleId,
      // make,
      // dealerCode,
      // serviceId,
      // metaVehicleFilterId,
      dealerLaborRateCodeId: parseInt(dealerLaborRateCodeId, 10),
      unscheduledLaborTime: convertToFloat(unscheduledLaborTime),
      // scheduledLaborTime,
      // overridePrice,
      // enabled,
      name,
      priceAlacarte: convertToFloat(priceAlacarte),
      // partsPrice,
      partsPriceAlacarte: convertToFloat(partsPriceAlacarte),
      // totalPrice,
      totalPriceAlacarte: convertToFloat(totalPriceAlacarte),
      dmsOpcode,
      position,
      replaceParts,
      modUser
    };

    const restUrl = "/ops/rest-db/updateTableData/pricingOverride";
    makeSecureRestApi(
      {
        url: restUrl,
        method: "post",
        data
      },
      data => {
        if (data) {
          console.log(data);
          this.clearAllFieldValidations(dealerLaborRule);
          this.updateStatusBox(
            this.context.appContext.localeStrings["xmm.portal.common.saved"],
            "success",
            true
          );
        } else {
          this.updateStatusBox(
            "Error in updating price override.",
            "error",
            false,
            true
          );
        }

        // refresh cells in case of error
        this.gridApi.applyTransaction({
          update: [dealerLaborRule]
        });
        this.gridApi.refreshCells({ force: true });

        // update packagePricing if it changes
        const packagePricing = this.isPackagePricing(dealerLaborRule);
        if (this.state.packagePricing !== packagePricing) {
          this.setState({ packagePricing });
        }
        if (callback) {
          callback();
        }
        // this.refreshPriceStatus();
      },
      () => {
        const msg =
          "There was an error while saving the override.  Please try again later.";
        toast.error(msg, {
          closeOnClick: true
        });
        if (callback) {
          callback();
        }
      }
    );
  };

  saveMaintenanceRule = (dealerLaborRule, field, callback) => {
    // this.updateStatusBox(
    //   this.context.appContext.localeStrings["xmm.portal.common.saving"],
    //   "pending",
    //   false
    // );
    const {
      dealerOperationRuleId,
      dealerCode,
      make,
      serviceId,
      variant,
      name,
      metaVehicleFilterId,
      enabled,
      dealerLaborRateCodeId,
      unscheduledLaborTime,
      scheduledLaborTime,
      priceAlacarte,
      overridePrice,
      // isLaborRule,
      // isPartsRule,
      partsPrice,
      partsPriceAlacarte,
      totalPrice,
      totalPriceAlacarte,
      dmsOpcode,
      replaceParts,
      payType,
      serviceTypeCode,
      skillLevel
    } = dealerLaborRule;
    // const isLaborRule =
    //   dealerLaborRule.scheduledLaborTime !== "" ||
    //   dealerLaborRule.unscheduledLaborTime !== "" ||
    //   dealerLaborRule.overridePrice !== "" ||
    //   dealerLaborRule.priceAlacarte !== ""
    //     ? "1"
    //     : "0";
    // const isPartsRule =
    //   dealerLaborRule.partsPrice !== "" ||
    //   dealerLaborRule.partsPriceAlacarte !== ""
    //     ? "1"
    //     : "0";
    const data = {
      dealerOperationRuleId,
      make,
      variant: make !== this.state.make ? "" : variant,
      dealerCode,
      serviceId,
      metaVehicleFilterId,
      dealerLaborRateCodeId,
      unscheduledLaborTime,
      scheduledLaborTime,
      overridePrice,
      enabled,
      name,
      priceAlacarte,
      // isLaborRule,
      // isPartsRule,
      partsPrice,
      partsPriceAlacarte,
      totalPrice,
      totalPriceAlacarte,
      overrideOpcodeFlag: 1,
      dmsOpcode,
      replaceParts: replaceParts ? 1 : 0,
      payType,
      serviceTypeCode,
      skillLevel
    };

    const restUrl = "/ops/proxyapi/ddsproxy/rest/proc/editDealerLaborRuleP";
    makeSecureRestApi(
      {
        url: restUrl,
        method: "post",
        data
      },
      data => {
        if (Array.isArray(data) && data.length !== 0) {
          console.log(data);
          if (data[0].isError === 0) {
            dealerLaborRule.vehicleCount = data[0].vehicleCount;
            dealerLaborRule.errorMsg = null;
            this.clearAllFieldValidations(dealerLaborRule);
            // toast.success("Your changes have been saved.");
            this.updateStatusBox(
              this.context.appContext.localeStrings["xmm.portal.common.saved"],
              "success",
              true
            );
          } else {
            let actualField = field;
            if (field === "metaVehicleFilterId") {
              actualField = "vehicleCount";
              if (!dealerLaborRule[field]) {
                dealerLaborRule.vehicleCount = "All";
              }
            }
            const { localeStrings } = this.context.appContext;
            const errorVals = data[0].message.split("|");
            let msg =
              localeStrings[
                "xmm.portal.operations.pricing_overrides.overlap.error"
              ];
            msg = msg.replace("%1", errorVals[0]);
            msg = msg.replace("%2", errorVals[1]);
            msg = msg.replace("%3", errorVals[2]);
            if (actualField) {
              this.setFieldValidation(dealerLaborRule, actualField, msg);
            }
            // toast.error(errorMsg);
            this.updateStatusBox(msg, "error", false, true);
          }

          // refresh cells in case of error
          this.gridApi.applyTransaction({
            update: [dealerLaborRule]
          });
          this.gridApi.refreshCells({ force: true });

          // update packagePricing if it changes
          const packagePricing = this.isPackagePricing(dealerLaborRule);
          if (this.state.packagePricing !== packagePricing) {
            this.setState({ packagePricing });
          }
        }
        if (callback) {
          callback();
        }
        this.refreshPriceStatus();
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error while saving the override.  Please try again later.";
        toast.error(msg, {
          closeOnClick: true
        });
        if (callback) {
          callback();
        }
      }
    );
  };
  refreshPriceStatus() {
    const { operation, updatePriceStatus } = this.props;
    updatePriceStatus(operation);
  }
  onSaveCellEdit(cellParams, actualField) {
    const dealerLaborRule = encodeNullValues(cellParams.data);
    const field = !actualField ? cellParams.colDef.field : actualField;

    const { errorMsgs } = dealerLaborRule;
    if (hasClientErrors(errorMsgs)) {
      // refresh cells in case of error
      this.gridApi.applyTransaction({
        update: [dealerLaborRule]
      });
      this.gridApi.refreshCells({ force: true });
      return false;
    }

    this.updateFields(dealerLaborRule, field, cellParams.value);
    this.saveRule(dealerLaborRule, field);
  }
  // used for pay code
  onChange = cxEvent => {
    const { name, value } = cxEvent.target;
    this.setState({ [name]: value });
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.loadDealerLaborRules();
  };
  handleGridSizeChanged = event => {
    const { clientWidth, clientHeight } = event;
    if (clientWidth && clientHeight) {
      this.sizeToFit();
    }
  };
  sizeToFit() {
    this.gridApi && this.gridApi.sizeColumnsToFit();
  }
  handleColumnResized = () => {
    // this.gridApi.resetRowHeights();
  };
  // Quick filter handler
  onSearchBoxChanged = event => {
    if (this.gridApi) {
      const { value } = event.target;
      this.gridApi.setQuickFilter(value);
    }
  };
  handleAddRule = event => {
    this.updateStatusBox("Adding Override", "pending");
    let existANYRule = false;
    const { globalRepairOpFlag, laborRules, vehicleSelectorMake } = this.state;
    laborRules.forEach(function (record) {
      if (record.make === "ANY") {
        existANYRule = true;
        return;
      }
    });
    if (globalRepairOpFlag) {
      this.addGlobalRepairPricingOverride(event);
    } else if (existANYRule && vehicleSelectorMake === "ANY") {
      this.setState({ showWarningOverride: true });
      this.updateStatusBox("", "success");
    } else {
      this.addMaintenancePricingOverride(event);
    }
    gtmEvents.gtmTrackEvent(
      "xmm.operations.pricing&opcodes.add_override_click"
    );
  };
  addGlobalRepairPricingOverride = () => {
    const { dealerCode, user } = this.context.appContext;
    const { userName } = user;
    const modUser = userName;
    const { serviceId } = this.props.operation;
    const {
      metaVehicleFilterId,
      metaVehicleFilterName,
      overrideName,
      vehicleSelectorMake
    } = this.state;
    const name = overrideName === "" ? metaVehicleFilterName : overrideName;
    const restUrl = "/ops/rest-db/addTableData/pricingOverride";
    const data = {
      make: vehicleSelectorMake,
      dealerCode,
      operationId: ~~serviceId.substring(1),
      metaVehicleFilterId: !metaVehicleFilterId ? null : metaVehicleFilterId,
      // dealerLaborRateCodeId: "",
      unscheduledLaborTime: null,
      name,
      priceAlacarte: null,
      partsPriceAlacarte: null,
      totalPriceAlacarte: null,
      modUser
    };
    makeSecureRestApi(
      {
        url: restUrl,
        method: "post",
        data
      },
      data => {
        if (data) {
          console.log(data);
          if (data) {
            this.loadDealerLaborRules(true);
            // toast.success("Your changes have been saved.");
            this.updateStatusBox("Added", "success", true);
          } else {
            this.loadDealerLaborRules(true);
            this.updateStatusBox(
              "Error adding pricing override.",
              "error",
              false
            );
          }
        }
        // const metaVehicleFilterName = getAllVehicleGroupName(
        //   make,
        //   this.allVehiclesTmpl
        // );
        const metaVehicleScope = !metaVehicleFilterId
          ? MVC_ALL_VEHICLES
          : MVC_CHOOSE_VEHICLES;
        closeAddOverridePopover();
        this.setState({
          metaVehicleFilterId,
          metaVehicleFilterName,
          metaVehicleScope,
          overrideName: ""
        });
      },
      () => {
        const msg =
          "There was an error while creating the override.  Please try again later.";
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );
  };
  addMaintenancePricingOverride = () => {
    const { dealerCode } = this.context.appContext;
    const { serviceId, variant } = this.props.operation;
    const { vehicleSelectorMake } = this.state;
    const make = vehicleSelectorMake;
    const { metaVehicleFilterId, metaVehicleFilterName, overrideName } =
      this.state;
    const name = overrideName === "" ? metaVehicleFilterName : overrideName;
    const restUrl = "/ops/proxyapi/ddsproxy/rest/proc/editDealerLaborRuleP";
    const data = {
      dealerOperationRuleId: "",
      make,
      variant: make !== this.state.make ? "" : variant,
      dealerCode,
      serviceId,
      metaVehicleFilterId,
      dealerLaborRateCodeId: "",
      unscheduledLaborTime: "",
      scheduledLaborTime: "",
      overridePrice: "",
      enabled: "",
      name,
      priceAlacarte: "",
      // isLaborRule: 1,
      // isPartsRule: 1,
      partsPrice: "",
      partsPriceAlacarte: "",
      totalPrice: "",
      totalPriceAlacarte: "",
      overrideOpcodeFlag: 0,
      dmsOpcode: "",
      replaceParts: 1,
      payType: "",
      serviceTypeCode: "",
      skillLevel: "",
      payType: "",
      serviceTypeCode: ""
    };
    makeSecureRestApi(
      {
        url: restUrl,
        method: "post",
        data
      },
      data => {
        if (Array.isArray(data) && data.length !== 0) {
          console.log(data);
          if (data[0].isError === 0) {
            this.loadDealerLaborRules(true);
            // toast.success("Your changes have been saved.");
            this.updateStatusBox("Added", "success", true);
          } else {
            this.loadDealerLaborRules(true);
            // toast.warning(
            //   "Overlaps detected! Please modify Price Override and save."
            // );
            const { localeStrings } = this.context.appContext;
            const errorVals = data[0].message.split("|");
            let msg =
              localeStrings[
                "xmm.portal.operations.pricing_overrides.overlap.error"
              ];
            msg = msg.replace("%1", errorVals[0]);
            msg = msg.replace("%2", errorVals[1]);
            msg = msg.replace("%3", errorVals[2]);
            this.updateStatusBox(msg, "error", false);
          }
        }
        const metaVehicleFilterName = getAllVehicleGroupName(
          make,
          this.allVehiclesTmpl
        );
        const metaVehicleScope = !metaVehicleFilterId
          ? MVC_ALL_VEHICLES
          : MVC_CHOOSE_VEHICLES;
        closeAddOverridePopover();
        this.setState({
          metaVehicleFilterId,
          metaVehicleFilterName,
          metaVehicleScope,
          overrideName: ""
        });
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error while creating the override.  Please try again later.";
        this.updateStatusBox(msg, "error", false, true);
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );
  };

  handleDeleteRules = event => {
    this.updateStatusBox("Deleting", "pending");
    const { globalRepairOpFlag } = this.state;
    if (globalRepairOpFlag) {
      this.handleDeleteGlobalRepairRules(event);
    } else {
      this.handleDeleteMaintenanceRules(event);
    }
    this.closeDeleteRulesModal();
    gtmEvents.gtmTrackEvent("xmm.operations.pricing&opcodes.delete_click");
  };

  handleDeleteGlobalRepairRules = () => {
    const { selectionlist } = this.state;
    const dealerOperationRuleIds = selectionlist.map(rule => {
      return rule.dealerOperationRuleId.toString();
    });
    const restUrl = "/ops/rest-db/deleteTableRow/pricingOverride";
    let index = 0;
    dealerOperationRuleIds.forEach(id => {
      const data = { id };
      makeSecureRestApi(
        {
          url: restUrl,
          method: "post",
          data
        },
        () => {
          index++;
          if (index < selectionlist.length) {
            return;
          }
          const res = this.gridApi.applyTransaction({
            remove: selectionlist
          });
          console.log("deleted dealer labor rules", res);
          // TODO: dlee
          // this.refreshPriceStatus();
          // toast.success("Selected pricing overrides are deleted.");
          this.updateStatusBox(
            this.context.appContext.localeStrings["xmm.portal.common.deleted"],
            "success",
            true
          );
        },
        () => {
          const msg =
            "There was an error while deleting the override.  Please try again later.";
          toast.error(msg, {
            closeOnClick: true
          });
        }
      );
    });
  };

  handleDeleteMaintenanceRules = () => {
    const { selectionlist } = this.state;
    const dealerOperationRuleIds = selectionlist.map(rule => {
      return rule.dealerOperationRuleId.toString();
    });
    if (dealerOperationRuleIds && dealerOperationRuleIds.length > 0) {
      const restUrl =
        "/ops/proxyapi/ddsproxy/rest/proc/deleteDealerLaborRulesP";
      const data = { dealerOperationRuleIds };
      makeSecureRestApi(
        {
          url: restUrl,
          method: "post",
          data
        },
        data => {
          if (data) {
            console.log(data);
            // TODO: Update Row
          }
          const res = this.gridApi.applyTransaction({
            remove: selectionlist
          });
          console.log("deleted dealer labor rules", res);
          this.refreshPriceStatus();
          this.loadDealerLaborRules(true);
          // toast.success("Selected pricing overrides are deleted.");
          this.updateStatusBox(
            this.context.appContext.localeStrings["xmm.portal.common.deleted"],
            "success",
            true
          );
        },
        error => {
          const msg = error["message"]
            ? error.message
            : "There was an error while deleting the override.  Please try again later.";
          toast.error(msg, {
            closeOnClick: true
          });
        }
      );
    }
  };
  handleSelectionChanged = () => {
    if (this.gridApi) {
      const selectedRows = this.gridApi.getSelectedRows();
      this.setState({ selectionlist: selectedRows });
    }
  };
  openDeleteRulesModal = () => {
    const { selectionlist } = this.state;
    if (selectionlist.length > 1) {
      this.deleteMsg = xlate(
        "xmm.portal.common.confirm_delete_x_overrides"
      ).replace("%1", selectionlist.length);
    } else {
      this.deleteMsg = xlate("xmm.portal.common.confirm_delete_an_override");
    }
    this.setState({ showDeleteRulesModal: true });
    gtmEvents.gtmTrackEvent("xmm.operations.open_parts_override_modal");
  };
  closeDeleteRulesModal = () => {
    this.setState({ showDeleteRulesModal: false });
  };
  onVehicleGroupBlur = () => {
    // TODO
  };
  onVehicleGroupChange = event => {
    const { data } = event.detail;
    const record = {
      metaVehicleScope: MVC_ALL_VEHICLES,
      metaVehicleFilterId: ""
    };
    if (record && data) {
      const { metaVehicleFilterId, metaVehicleScope, name } = data;
      record.metaVehicleScope = metaVehicleScope;
      if (metaVehicleScope === MVC_ALL_VEHICLES) {
        record.metaVehicleFilterId = "";
      } else {
        record.metaVehicleScope = MVC_CHOOSE_VEHICLES;
        record.metaVehicleFilterId = metaVehicleFilterId;
      }
      this.setState({
        metaVehicleScope: record.metaVehicleScope,
        metaVehicleFilterId: record.metaVehicleFilterId,
        metaVehicleFilterName: name,
        overrideName: name
      });
    }
  };

  renderDeleteButton = (hasLaborRules, selectionlist) => {
    const ruleLabel = selectionlist.length.toString();
    const disabled = selectionlist.length === 0;
    const deleteLabel =
      selectionlist.length === 0
        ? this.deleteButtonLabel
        : `${this.deleteButtonLabel} (${ruleLabel})`;
    const deleteButton = hasLaborRules ? (
      <Button
        htmlId="deleteRuleButton"
        buttonStyle="secondary"
        disabled={disabled}
        onClick={this.openDeleteRulesModal}
      >
        {deleteLabel}
      </Button>
    ) : (
      ""
    );
    return deleteButton;
  };
  renderOverridesPopover = () => {
    const { localeStrings } = this.context.appContext;
    const { loadOperation } = this.context;
    const { globalRepairOpFlag } = loadOperation;
    const { isDMSPlusEnabled } = this.state;

    return (
      <div>
        <p className="xmm-popover-text">
          {isDMSPlusEnabled
            ? localeStrings[
                "xmm.portal.operations.pricing_overrides_dms.popover"
              ]
            : globalRepairOpFlag
            ? localeStrings[
                "xmm.portal.operations.pricing.override_tip_for_global"
              ]
            : localeStrings["xmm.portal.operations.pricing_overrides.popover"]}
        </p>
      </div>
    );
  };
  renderAddOverrideWidge = () => {
    const { vehicleGroupMakeList } = this.context.appContext;
    const { variant, dealerCode, globalRepairOpFlag } = this.props.operation;
    const {
      make,
      // metaVehicleFilterName,
      metaVehicleScope,
      metaVehicleFilterId,
      overrideName,
      vehicleSelectorMake
    } = this.state;
    const descriptionPlaceholder =
      overrideName === ""
        ? getAllVehicleGroupName(vehicleSelectorMake, this.allVehiclesTmpl)
        : "";
    const vehicleGroup = {
      make: vehicleSelectorMake,
      variant,
      dealerCode,
      metaVehicleScope,
      metaVehicleFilterId
    };
    const clsMake = make === "ANY" ? "" : "hide";
    const makeDisabled = false; // globalRepairOpFlag;
    const vehicleGroupDisabled = vehicleSelectorMake === "ANY";
    const descriptionDisabled =
      !globalRepairOpFlag && vehicleSelectorMake === "ANY";
    return (
      <form autoComplete="off">
        <SelectInput
          htmlId="makeSelect"
          label="Make"
          name="make"
          className={clsMake}
          // layout="horizontal"
          displayDeselectOption={false}
          displayPlaceholder={false}
          placeholder="Select"
          onChange={this.onMakeChange}
          options={vehicleGroupMakeList}
          value={vehicleSelectorMake}
          disabled={makeDisabled}
          required={true}
        />
        <div className="xmm-add-labor-rules-vehicles-label">
          {this.vehicleGroupLabel}
          <span className="xmm-red-label">*</span>{" "}
        </div>
        <VehicleGroupSelector
          ref={this.vehicleGroupSelectorRef}
          data={vehicleGroup}
          // error={errors.metaVehicleFilterId}
          context={this.context.appContext}
          hideAllVehicles={false}
          autoLoad={true}
          // Notes: below two flags is a workaround for modal under the popover
          fromEditor={true}
          visibleOnInit={false}
          onBlur={this.onVehicleGroupBlur}
          onChange={this.onVehicleGroupChange}
          disabled={vehicleGroupDisabled}
        />
        <TextInput
          htmlId="overrideName"
          label={this.descriptionLabel}
          name="overrideName"
          maxLength={MAX_VEHICLE_GROUP_NAME_LENGTH}
          onChange={this.onChange}
          displayPlaceholder={true}
          placeholder={descriptionPlaceholder}
          value={overrideName}
          disabled={descriptionDisabled}
        />
        <Button
          htmlId="continueBtn"
          text="Continue to set details"
          buttonStyle="secondary"
          size="small"
          style={{ width: "100%" }}
          onClick={this.handleAddRule}
        >
          {this.continueToSetLabel}
        </Button>
      </form>
    );
  };
  /* Cell renderer to display error tip for Name cell */
  nameCellRenderer(params) {
    const name = params.value;
    const {
      externallyManaged,
      errorMsgs,
      errors,
      dealerOperationRuleId,
      warningMessage
    } = params.data;
    if (hasErrors(errors) && errorMsgs) {
      const errorSummary = getErrorMessages(errors, errorMsgs);
      const keyId = dealerOperationRuleId.toString() + "-errorTip";
      const errorTip = (
        <div className="xmm-override-msg">
          {" "}
          {name}{" "}
          <Tooltip htmlId={keyId} tooltipContent={errorSummary}>
            <IconError className="xmm-override-error pull-right" />
          </Tooltip>
        </div>
      );
      return errorTip;
    } else if (!doesEmpty(warningMessage)) {
      const keyId = dealerOperationRuleId.toString() + "-warningTip";
      const warningTip = (
        <div className="xmm-override-msg">
          {" "}
          {name}{" "}
          <Tooltip htmlId={keyId} tooltipContent={warningMessage}>
            <IconWarning className="xmm-override-warn pull-right" />
          </Tooltip>
        </div>
      );
      return warningTip;
    } else if (isTrue(externallyManaged)) {
      const { localeStrings } = this.context.appContext;
      const externallyManagedTooltip =
        localeStrings[
          "xmm.portal.operations.pricing_overrides.externally_managed"
        ];
      const keyId = dealerOperationRuleId.toString() + "-externallyManagedTip";
      const externallyManagedTip = (
        <div className="xmm-override-msg">
          {" "}
          {name}{" "}
          <Tooltip htmlId={keyId} tooltipContent={externallyManagedTooltip}>
            <IconInfoOutline className="xmm-override-info pull-right" />
          </Tooltip>
        </div>
      );
      return externallyManagedTip;
    } else {
      return name;
    }
  }
  vehicleCellRenderer(params) {
    const { value } = params;
    const vehicleCount = toEmptyStringIfUndefined(value);
    if (!vehicleCount) {
      return "-";
    } else {
      const popOverWidget = (
        <div>
          <span className="badge-cobalt">{params.value}</span>
        </div>
      );
      return popOverWidget;
    }
  }
  updateInput = (event, params) => {
    console.log("groupname edited", params.value, event.target.value);
    this.setState({
      groupName: event.target.value
    });
  };

  updateColumnDefs(checked) {
    const { pricingMethod } = this.state;
    const columnDefs = this.getColumnDefs(
      this.context.appContext,
      pricingMethod,
      checked
    );
    // need to call setColumnDefs and not setState({ columnDefs }) since it choked
    this.gridApi.setColumnDefs(columnDefs);
    setTimeout(() => {
      this.sizeToFit();
    }, 0);
  }

  // Vehicle Group Selector's methods
  loadVehicleGroupNameList(vehicleGroup) {
    const context = this.context.appContext;
    const { vehicleGroupCallbackParams } = context;
    const { metaVehicleFilterId } = this.state.data;
    vehicleGroupCallbackParams.vehicleGroupCallback =
      this.handleVehicleGroupCallback;
    vehicleGroupCallbackParams.vehicleGroup = vehicleGroup;
    getGroupNameList(metaVehicleFilterId, context);
  }
  handleVehicleGroupCallback = (
    dealerMakeVehiclesGroupMap,
    makeVehicleGroupsMap,
    sortedVehicleGroups
  ) => {
    // update VehicleGroupSelector as well
    if (this.vehicleGroupSelectorRef && this.vehicleGroupSelectorRef.current) {
      this.vehicleGroupSelectorRef.current.handleVehicleGroupCallback(
        dealerMakeVehiclesGroupMap,
        makeVehicleGroupsMap,
        sortedVehicleGroups
      );
    }
    const vehicleGroupOptions = createVehicleGroupOptions(
      makeVehicleGroupsMap,
      this.state.data.make
    );
    this.setVehicleGroupName(makeVehicleGroupsMap);
    this.setState({
      dealerMakeVehiclesGroupMap,
      makeVehicleGroupsMap,
      sortedVehicleGroups,
      vehicleGroupOptions,
      filteredVehicleGroupOptions: [...vehicleGroupOptions]
    });
    const { vehicleGroupCallbackParams } = this.context.appContext;
    const { vehicleGroup } = vehicleGroupCallbackParams;
    if (
      vehicleGroup &&
      this.manageVehGpModalRef &&
      this.manageVehGpModalRef.current
    ) {
      this.manageVehGpModalRef.current.setSelectedItem(vehicleGroup);
    }
  };
  setVehicleGroupName = makeVehicleGroupsMap => {
    const { make, metaVehicleFilterId, metaVehicleScope } = this.state.data;
    if (metaVehicleScope === "1") {
      this.setState({
        metaVehicleGroupName: getAllVehicleGroupName(make, this.allVehiclesTmpl)
      });
    } else if (metaVehicleFilterId !== "") {
      const vehicleGroups = makeVehicleGroupsMap[make];
      if (vehicleGroups) {
        const matches = vehicleGroups.filter(vg => {
          return !isDifferentValue(vg.metaVehicleFilterId, metaVehicleFilterId);
        });
        if (matches.length !== 0) {
          const vehicleGroup = matches[0];
          const metaVehicleGroupName = vehicleGroup.name
            ? vehicleGroup.name
            : "Missing Vehicle Group Name and Description";
          this.setState({ metaVehicleGroupName });
        }
      }
    }
  };
  onDealerNameFilterEvent = event => {
    event.preventDefault();
    event.stopPropagation();
    const vehicleGroup = event.detail;
    // event.detail && Object.keys(event.detail).length !== 0
    //   ? event.detail
    //   : null;
    this.setState({ data: vehicleGroup }, () => {
      this.loadVehicleGroupNameList(vehicleGroup);
    });
  };
  // for create vehicle group modal
  onSetNamedVehicleFilter = event => {
    const { data } = this.state;
    if (!data) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    const createEvent = { detail: { data } };
    this.onVehicleGroupChange(createEvent);
  };
  onRemoveVehicleGroupFromList = event => {
    const { make, metaVehicleFilterId, sortedVehicleGroups } = this.state;
    if (!sortedVehicleGroups) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    const metaVehicleFilter = event.detail;
    if (metaVehicleFilter) {
      const { sortedVehicleGroups } = this.state;
      const sortedVehicleGroupsMap = findMakeVehicleGroupMap(
        metaVehicleFilter,
        sortedVehicleGroups
      );
      removeItemFromMap(metaVehicleFilter, sortedVehicleGroupsMap);
      const newState = { sortedVehicleGroups };
      if (metaVehicleFilterId === metaVehicleFilter.metaVehicleFilterId) {
        newState.metaVehicleScope = MVC_ALL_VEHICLES;
        newState.metaVehicleFilterName = getAllVehicleGroupName(
          make,
          this.allVehiclesTmpl
        );
        newState.metaVehicleFilterId = "";
        newState.overrideName = "";
      }
      this.setState(newState);
    }
  };

  openManageVehicleGroupsFromEditor = event => {
    const { selectedVehicleGroup, sortedVehicleGroups } = event.detail;
    this.setState(
      {
        selectedVehicleGroup,
        sortedVehicleGroups,
        showManageVehicleGroupsModal: true
      },
      () => {
        // closeAddOverridePopover();
        if (this.manageVehGpModalRef && this.manageVehGpModalRef.current) {
          this.manageVehGpModalRef.current.setSelectedItem(
            selectedVehicleGroup
          );
        }
      }
    );
  };

  closeManageVehicleGroupsModal = () => {
    this.setState({ showManageVehicleGroupsModal: false });
  };

  renderManageVehicleGroupsModal = context => {
    const groupChild = <div />;
    let widget = null;
    if (this.state.showManageVehicleGroupsModal) {
      widget = (
        <ManageVehicleGroupModal
          ref={this.manageVehGpModalRef}
          show={this.state.showManageVehicleGroupsModal}
          title={xlate("xmm.portal.vehiclegroups.modal_lbl")}
          closeModal={event => {
            if (
              context.discardUnsavedChanges(
                event,
                this.closeManageVehicleGroupsModal,
                this.manageVehGpModalRef.current.isDirty
              )
            ) {
              this.closeManageVehicleGroupsModal();
            }
          }}
          sortedVehicleGroups={this.state.sortedVehicleGroups}
        >
          {groupChild}
        </ManageVehicleGroupModal>
      );
    }
    return widget;
  };

  openCreateVehicleGroupFromEditor = event => {
    const { make, showCreateVehicleGroupModal, sortedVehicleGroups } =
      event.detail;
    this.setState({
      vehicleGroupMake: make,
      showCreateVehicleGroupModal,
      sortedVehicleGroups
    });
    // closeAddOverridePopover();
  };
  onCloseCreateVehicleGroup = () => {
    this.showCreateVehicleGroupModal(false);
  };
  showCreateVehicleGroupModal = showCreateVehicleGroupModal => {
    this.setState({ showCreateVehicleGroupModal });
  };
  renderCreateVehicleGroupModal = context => {
    let widget = null;
    if (this.state.showCreateVehicleGroupModal) {
      const { dealerCode, makeVariantMap } = context;
      const { vehicleGroupMake } = this.state;
      const { variant } = makeVariantMap[vehicleGroupMake];
      widget = (
        <CreateVehicleGroupModal
          ref={this.createVehGpModalRef}
          show={this.state.showCreateVehicleGroupModal}
          title={xlate("xmm.portal.vehiclegroups.create_lbl")}
          closeModal={event => {
            if (
              context.discardUnsavedChanges(
                event,
                this.onCloseCreateVehicleGroup,
                this.createVehGpModalRef.current.isDirty
              )
            ) {
              this.onCloseCreateVehicleGroup();
            }
          }}
          dealerCode={dealerCode}
          make={vehicleGroupMake}
          variant={variant}
          sortedVehicleGroups={this.state.sortedVehicleGroups}
        >
          {<div />}
        </CreateVehicleGroupModal>
      );
    }
    return widget;
  };
  showLoadingMask(showMask) {
    this.setState({ showMask });
  }
  renderLoadingMask(showMask) {
    return showMask ? (
      <div className="xmm-loading-mask">
        <div className="xmm-loading-label"> {""} </div>
        <LoadingIndicator htmlId="overridesMask" size="large" />
      </div>
    ) : (
      ""
    );
  }
  updateRulesToPackagePricing = rulesToUpdate => {
    let count = 0;
    rulesToUpdate.forEach(rule => {
      const dealerLaborRule = encodeNullValues(rule);
      this.makePackagePricing(dealerLaborRule);
      this.saveRule(dealerLaborRule, null, () => {
        count++;
        if (count >= rulesToUpdate.length) {
          this.updateGridView(false);
          this.setState({ showUncheckModal: false });
        }
      });
    });
  };
  proceedUncheck() {
    // make all overrides have package pricing
    const { laborRules } = this.state;
    const rulesToUpdate = laborRules.filter(rule => {
      return !this.isPackagePricing(rule);
    });
    if (rulesToUpdate.length !== 0) {
      this.updateRulesToPackagePricing(rulesToUpdate);
    } else {
      this.updateGridView(false);
      this.setState({ showUncheckModal: false });
    }
  }
  cancelUncheck() {
    this.setState({ checked: false });
    this.setState({ showUncheckModal: false });
  }
  renderUncheckConfirmation = showUncheckModal => {
    const { localeStrings } = this.context.appContext;
    const msg =
      localeStrings["xmm.portal.operations.pricing_overrides.proceed_uncheck"];
    return showUncheckModal ? (
      <Confirmation
        htmlId="uncheckDifferentPrices"
        message={msg}
        proceedButtonStyle="danger"
        show={showUncheckModal}
        actionFunction={this.proceedUncheck}
        closeDialog={this.cancelUncheck}
      />
    ) : (
      ""
    );
  };
  renderWarningOverride = showWarningOverride => {
    const { localeStrings } = this.context.appContext;
    const warnTitle = localeStrings["xmm.portal.common.warn_label"];
    const warnMessage = localeStrings["xmm.portal.warning_override_msg"];
    return showWarningOverride ? (
      <SimpleModalDialog
        title={warnTitle}
        message={warnMessage}
        show={showWarningOverride}
        actionButtonLabel={"OK"}
        dialogHtmlId={"warningOverrideId"}
        actionHtmlId={"actionId"}
        titleId={warnTitle + "Id"}
        messageId={warnTitle + "MsgId"}
        actionButtonLabelId={"xmm.portal.common.ok_button"}
        doAction={() => {
          this.setState({ showWarningOverride: false });
        }}
        doClose={() => {
          this.setState({ showWarningOverride: false });
        }}
      />
    ) : (
      ""
    );
  };
  renderDeleteConfirmation = showDeleteRulesModal => {
    return showDeleteRulesModal ? (
      <Confirmation
        htmlId="deleteOverrides"
        message={this.deleteMsg}
        proceedButtonStyle="danger"
        show={showDeleteRulesModal}
        actionFunction={this.handleDeleteRules}
        closeDialog={this.closeDeleteRulesModal}
      />
    ) : (
      ""
    );
  };
  /* Handler to update statusbox state props */
  updateStatusBox(msg, type, autoClose, errorInTooltip) {
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
    sleep(0).then(() => {
      this.setState({
        statusMsg: msg,
        autoClose,
        statusType: type,
        errorInTooltip
      });
    });
  }
  renderStatusBox() {
    return this.state.statusMsg ? (
      <div className="xmm-status-override">
        <StatusBox
          htmlId="statusBox"
          type={this.state.statusType}
          autoClose={this.state.autoClose}
          linkHtml={null}
          message={this.state.statusMsg}
          autoCloseTime={1500}
          errorInTooltip={this.state.errorInTooltip}
        />
      </div>
    ) : (
      ""
    );
  }

  onMakeChange = cxEvent => {
    const { vehicleSelectorMake } = this.state;
    const { value } = cxEvent.target;
    if (vehicleSelectorMake !== value) {
      const metaVehicleFilterName = getAllVehicleGroupName(
        value,
        xlate("xmm.portal.common.all_make_vehicles")
      );
      this.setState({
        metaVehicleScope: MVC_ALL_VEHICLES,
        metaVehicleFilterId: "",
        metaVehicleFilterName,
        overrideName: "",
        vehicleSelectorMake: value
      });
    }
  };
  handleShowPartsGrid = rule => {
    this.getPartOverrides(rule);
    gtmEvents.gtmTrackEvent("xmm.operations.open_parts_override_modal");
  };
  getPartOverrides = rule => {
    const { globalRepairOpFlag, partManufacturerCodeMap } = this.state;
    const { dealerOperationRuleId, make } = rule;
    const method = "get";
    const params = { dealerOperationRuleId };

    const url = globalRepairOpFlag
      ? "/ops/partoverride/getPartOverrides"
      : "/ops/proxyapi/ddsproxy/rest/proc/getPartOverrides";

    makeSecureRestApi(
      {
        url,
        method,
        params
      },
      data => {
        let id = 0;
        const partsList = globalRepairOpFlag ? data.partOverrides : data;
        partsList.forEach(item => {
          item.rowId = ++id;
          item.partType = "part";
          item.dtDmsPartCode = item.partMake;
          if (!item.relationship) {
            item.relationship = "Primary";
          }
        });
        const defaultPartManufacturerCode = partManufacturerCodeMap[make]
          ? partManufacturerCodeMap[make].oemCode
          : "OT";
        this.setState({
          clickedRule: rule,
          partsList,
          defaultPartManufacturerCode,
          partsOverridesModified: false,
          showPartsGrid: true
        });
      },
      () => {
        // toast.error(error.message);
      }
    );
  };
  partsCellRenderer = params => {
    if (!params || !params.data) {
      return "";
    }
    // if (!params.value || params.value === 0 || params.value === "0") {
    //   return "Flat Rate";
    // }
    // const { description, make } = params.data;
    let ruleParts = null;
    const { partOverrideCount } = params.data;
    if (partOverrideCount > 0) {
      ruleParts =
        partOverrideCount + " " + (partOverrideCount > 1 ? "parts" : "part");
    }
    return (
      <span className="xmm-parts-cell">
        <span>{ruleParts}</span>
        <span
          className="edit-parts-icon"
          onClick={() => {
            this.handleShowPartsGrid(params.data);
          }}
        >
          <i className="far fa-edit" />
        </span>
      </span>
    );
  };
  addParts = parts => {
    const { globalRepairOpFlag } = this.state;
    const { dealerCode } = this.state.clickedRule;
    const url = globalRepairOpFlag
      ? `/ops/partoverride/addPartOverride/${dealerCode}`
      : "/ops/proxyapi/ddsproxy/rest/proc/addPartOverride";
    parts.forEach(part => {
      const {
        dealerOperationRuleId,
        partMake,
        partName,
        priceSource,
        oemPartNumber,
        quantity,
        dmsPrice,
        relationship
      } = part;
      const qty =
        typeof quantity === "string" ? parseInt(quantity, 10) : quantity;
      makeSecureRestApi(
        {
          url,
          method: "post",
          data: {
            dealerOperationRuleId: parseInt(dealerOperationRuleId, 10),
            partMake,
            partName: !partName ? "" : partName,
            oemPartNumber,
            quantity: qty,
            unitPrice: !dmsPrice ? "" : dmsPrice,
            priceSource: !priceSource ? "" : priceSource,
            relationship
          }
        },
        () => {
          //
        },
        () => {
          toast.error("Error in adding part " + part.oemPartNumber);
        }
      );
    });
  };
  updateParts = parts => {
    const { globalRepairOpFlag } = this.state;
    const { dealerCode } = this.state.clickedRule;
    const url = globalRepairOpFlag
      ? `/ops/partoverride/updatePartOverride/${dealerCode}`
      : "/ops/proxyapi/ddsproxy/rest/proc/updatePartOverride";

    parts.forEach(part => {
      const {
        dealerPartOverrideId,
        dealerOperationRuleId,
        partMake,
        partName,
        priceSource,
        oemPartNumber,
        quantity,
        // unitPrice,
        // dmsPrice,
        relationship
      } = part;

      const qty =
        typeof quantity === "string" ? parseInt(quantity, 10) : quantity;
      makeSecureRestApi(
        {
          url,
          method: "post",
          data: {
            dealerPartOverrideId,
            dealerOperationRuleId,
            partMake,
            partName: !partName ? "" : partName,
            oemPartNumber,
            quantity: qty,
            unitPrice: "",
            priceSource: !priceSource ? "" : priceSource,
            relationship
          }
        },
        () => {
          //
        },
        () => {
          toast.error("Error in updating part " + part.oemPartNumber);
        }
      );
    });
  };
  deleteParts = parts => {
    const { globalRepairOpFlag } = this.state;
    const url = globalRepairOpFlag
      ? "/ops/partoverride/deletePartOverride"
      : "/ops/proxyapi/ddsproxy/rest/proc/deletePartOverride";
    parts.forEach(part => {
      const { dealerPartOverrideId } = part;
      makeSecureRestApi(
        {
          url,
          method: "get",
          params: {
            dealerPartOverrideId
          }
        },
        () => {
          //
        },
        () => {
          toast.error("Error in deleting part " + part.oemPartNumber);
        }
      );
    });
  };
  onGetPartsPricing = (parts, callback) => {
    const uniquePartNumberMap = {};
    const partNumbers = parts
      .filter(p => {
        if (p.oemPartNumber) {
          p.dmsPending = true;
          if (!uniquePartNumberMap[p.oemPartNumber]) {
            uniquePartNumberMap[p.oemPartNumber] = p;
            return true;
          }
        }
        return false;
      })
      .map(p => {
        return {
          partNumber: p.oemPartNumber,
          manufacturerCode: p.dtDmsPartCode
        };
      });
    if (partNumbers.length === 0) {
      return;
    }

    const { dealerCode, make, serviceId } = this.state.clickedRule;

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Api-Token":
        "Sm9obmF0aGFuIFRvbGR0CU2FjaGluIEIgTW9kaQ==BRGluZXNoIENoYW5kcmE=D"
    };

    const data = {
      appSource: "CatalogManagePricing",
      operationId: serviceId,
      operationSource: "DealerPublishedCatalog",
      vin: "",
      payType: "C",
      serviceType: "",
      partNumbers
    };

    makeSecureRestApi(
      {
        url: `/ops/proxyapi/catalogadminproxy/opsadmin/operations/getPartsPricingAndInventory/make/${make}/dealerCode/${dealerCode}`,
        headers,
        method: "post",
        data
      },
      response => {
        const { success, statusCode } = response;
        if (!success || statusCode !== 200) {
          this.setDmsPending(parts, false);
        } else {
          const dmsPartsMap = response.parts
            .filter(p => !p.code)
            .reduce((map, obj) => {
              map[obj.partNumber] = obj;
              return map;
            }, {});
          parts.forEach(p => {
            if (p.oemPartNumber) {
              p.dmsPending = false;
              const dmsPart = dmsPartsMap[p.oemPartNumber];
              if (dmsPart) {
                p.quantityAvailable = dmsPart.quantityAvailable;
                p.dmsPrice = dmsPart.partPrice;
                p.unitPrice = dmsPart.partPrice;
                p.priceSource = "DMS";
                if (!p.partName) {
                  p.partName = dmsPart.partDescription;
                }
              }
            }
          });
        }
        callback(parts);
      },
      () => {
        this.setDmsPending(parts, false);
        callback(parts);
      }
    );
  };
  setDmsPending(parts, flag) {
    parts.forEach(p => {
      if (p.oemPartNumber) {
        p.dmsPending = flag;
      }
    });
  }

  render() {
    const { localeStrings } = this.context.appContext;

    const {
      make,
      hasLaborRules,
      selectionlist,
      serviceKind,
      checked,
      pricingMethod,
      showDeleteRulesModal,
      showWarningOverride,
      showUncheckModal,
      showPartsGridSpeedBump,
      showMask,
      isDMSPlusEnabled
      // laborRules
    } = this.state;
    const clsHideRow =
      make === "ANY" ||
      pricingMethod === 0 ||
      serviceKind === "repair" ||
      isDMSPlusEnabled
        ? "hidden"
        : "";
    const searchClass = hasLaborRules ? "xmm-input-search" : "hidden";
    // const gridClass = hasLaborRules ? "" : "hide";
    const deleteButton = this.renderDeleteButton(hasLaborRules, selectionlist);
    const uncheckConfirmationDialog =
      this.renderUncheckConfirmation(showUncheckModal);
    const deleteConfirmationDialog =
      this.renderDeleteConfirmation(showDeleteRulesModal);
    const warningOverrideDialog =
      this.renderWarningOverride(showWarningOverride);
    const overridesPopover = this.renderOverridesPopover();
    const addOverrideWidget = this.renderAddOverrideWidge();

    // vehicle group modal
    const createVehicleGroupModal = this.renderCreateVehicleGroupModal(
      this.context.appContext
    );
    const manageVehicleGroupsModal = this.renderManageVehicleGroupsModal(
      this.context.appContext
    );
    const maskWidget = this.renderLoadingMask(showMask);
    const statusBox = this.renderStatusBox();
    // const { globalRepairOpFlag } = this.state;
    const disableAnyOverride = false;
    // !globalRepairOpFlag && make === "ANY" && laborRules.length > 0;
    // Add components within slider
    // const title = "Add Parts - " + this.props.operation.internalName;

    const dirtyPopup = (
      <DirtyCheckPopup
        showDirtyModal={showPartsGridSpeedBump}
        title={localeStrings["xmm.portal.speed_bump.title"]}
        message={localeStrings["xmm.portal.speed_bump.message"]}
        okText={localeStrings["xmm.portal.speed_bump.discard"]}
        cancelText={localeStrings["xmm.portal.speed_bump.stay_on_page"]}
        cancelAction={() => this.setState({ showPartsGridSpeedBump: false })}
        discardAction={() => this.setState({ showPartsGrid: false })}
      />
    );

    const partsGridModal = (
      <ModalDialog
        htmlId="add-edit-parts-grid"
        show={this.state.showPartsGrid}
        backdrop={"static"}
        animation={false}
        className="xmm-add-parts-modal"
        // header={<ModalDialog.Title>{title}</ModalDialog.Title>}
        header={
          <>
            <h4>Add Parts</h4>
            <h4>{this.props.operation.internalName}</h4>
          </>
        }
        onHide={() => {
          if (this.state.partsOverridesModified) {
            this.setState({ showPartsGridSpeedBump: true });
          } else {
            this.setState({ showPartsGrid: false });
          }
        }}
        footer={false}
      >
        <div>
          <AddPartsModal
            partsList={this.state.partsList}
            make={this.state.clickedRule.make}
            defaultPartManufacturerCode={this.state.defaultPartManufacturerCode}
            dealerOperationRuleId={this.state.clickedRule.dealerOperationRuleId}
            replaceParts={this.state.clickedRule.replaceParts}
            partManufacturerCodeMap={this.state.partManufacturerCodeMap}
            showIncludeOEMParts={this.props.showIncludeOEMParts}
            onModified={() => this.setState({ partsOverridesModified: true })}
            onGetPartsPricing={this.onGetPartsPricing}
            onUpdateParts={(updatedPartsList, replacePartsFlag) => {
              console.log("onUpdateParts...");
              this.setState({ showPartsGrid: false });
              const adds = updatedPartsList.filter(
                part => !part.dealerPartOverrideId
              );
              const updates = updatedPartsList.filter(
                part => !!part.dealerPartOverrideId && !!part.modified
              );
              const currentPartsMap = {};
              updatedPartsList.forEach(part => {
                currentPartsMap[part.rowId] = part;
              });
              const deletes = [];
              this.state.partsList.forEach(p => {
                if (!currentPartsMap[p.rowId]) {
                  deletes.push(p);
                }
              });

              this.addParts(adds);
              this.updateParts(updates);
              this.deleteParts(deletes);

              const { clickedRule } = this.state;
              if (!!clickedRule.replaceParts !== replacePartsFlag) {
                clickedRule.replaceParts = replacePartsFlag;
                // need to save DealerOperationRule now if flag changed
                this.saveRule(clickedRule, "parts");
              }
              clickedRule.partOverrideCount = updatedPartsList.length;
              this.gridApi?.refreshCells({ force: true });
              gtmEvents.gtmTrackEvent(
                "xmm.operations.pricing&opcodes.addpartsmodal.save_click"
              );
            }}
          />
        </div>
      </ModalDialog>
    );
    const gridClassName = hasLaborRules
      ? isDMSPlusEnabled
        ? "xmm-overrides-with-dms"
        : "xmm-overrides"
      : "hidden";

    return (
      <React.Fragment>
        <Grid>
          <Row hidden={pricingMethod === 0}>
            <Col xs={12} md={12} className="full-col">
              <hr />
            </Col>
          </Row>
          <Row className={clsHideRow}>
            <Col xs={12} md={12} className="full-col">
              <div className="xmm-checkbox-container xmm-horizontal-form">
                <input
                  className="form-checkbox"
                  name="setDifPrices"
                  id="setDifPrices"
                  type="checkbox"
                  checked={checked}
                  onChange={this.onChangeCheckbox}
                />
                <span className="xmm-checkmark" />
                <span className="label-checkbox full-label">
                  {this.setDiffPriceLabel}
                </span>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12} className="full-col">
              <div className="xmm-header-info">
                <h4>{this.overridesLabel}</h4>
                <Popover
                  htmlId="popoverOverrides"
                  popoverContent={overridesPopover}
                  trigger={["click", "outsideClick"]}
                  position="bottom"
                >
                  <IconInfoOutline
                    htmlId="overridesInfo"
                    className="hand-cursor"
                  />
                </Popover>
                <ButtonToolbar className="xmm-right-content">
                  {statusBox}
                  {deleteButton}
                  <div className={searchClass}>
                    <input
                      type="text"
                      id="preview-search-box"
                      className="xmm-input"
                      placeholder={this.searchLabel}
                      onInput={this.onSearchBoxChanged}
                    />
                  </div>
                  <Popover
                    htmlId="popoverAdd"
                    className="xmm-add-override-popover"
                    popoverContent={addOverrideWidget}
                    position="left"
                    trigger={["click"]}
                    // trigger={["click", "outsideClick"]}
                  >
                    <Button
                      htmlId="addOverrideBtn"
                      buttonStyle="primary"
                      disabled={disableAnyOverride}
                    >
                      {this.addOverrideLabel}
                    </Button>
                  </Popover>
                </ButtonToolbar>
              </div>

              <div
                id="overrideGrid"
                className={`ag-grid-container ag-theme-balham ${gridClassName}`}
              >
                <AgGridReact
                  // getRowNodeId={this.getRowNodeId}
                  columnDefs={this.state.columnDefs}
                  defaultColDef={this.state.defaultColDef}
                  rowData={this.state.rowData}
                  rowSelection={this.state.rowSelection}
                  animateRows={true}
                  multiSortKey={this.state.multiSortKey}
                  sortingOrder={this.state.sortingOrder}
                  suppressRowClickSelection={true}
                  suppressMenuHide={false}
                  suppressContextMenu={true}
                  onGridReady={this.onGridReady}
                  onGridSizeChanged={this.handleGridSizeChanged}
                  onCellValueChanged={this.onCellValueChanged}
                  onColumnResized={this.handleColumnResized}
                  onSelectionChanged={this.handleSelectionChanged}
                  sideBar={this.state.sideBar}
                  localeText={this.state.localeText}
                  components={this.state.components}
                  loadingOverlayComponent={this.state.loadingOverlayComponent}
                  loadingOverlayComponentParams={
                    this.state.loadingOverlayComponentParams
                  }
                  noRowsOverlayComponent={this.state.noRowsOverlayComponent}
                  noRowsOverlayComponentParams={
                    this.state.noRowsOverlayComponentParams
                  }
                  columnTypes={this.state.columnTypes}
                  statusBar={this.state.statusBar}
                  singleClickEdit={true}
                  stopEditingWhenCellsLoseFocus={true}
                  enableRangeSelection={false}
                  enableCellTextSelection={true}
                  enableBrowserTooltips={true}
                  // rowHeight={25}
                />
              </div>
              {deleteConfirmationDialog}
              {warningOverrideDialog}
              {uncheckConfirmationDialog}
            </Col>
          </Row>
        </Grid>
        {maskWidget}
        {createVehicleGroupModal}
        {manageVehicleGroupsModal}
        {partsGridModal}
        {dirtyPopup}
      </React.Fragment>
    );
  }
}

export default OverridesGrid;

OverridesGrid.propTypes = {
  operation: PropTypes.object,
  updatePriceStatus: PropTypes.func
};
// TODO - sample renderer
function badgeCellRenderer(params) {
  const vehicleCount = toEmptyStringIfUndefined(params.value);
  if (!vehicleCount) {
    return "-";
  } else {
    const iconHtml = '<i class="fas fa-check"></i>' + params.value;
    return iconHtml;
  }
}

function hasErrors(errors) {
  if (!errors) {
    return false;
  }
  const keys = Object.keys(errors);
  for (let index = 0; index < keys.length; index++) {
    if (errors[keys[index]]) {
      return true;
    }
  }
  return false;
}

function hasClientErrors(errorMsgs) {
  if (!errorMsgs) {
    return false;
  }
  const keys = Object.keys(errorMsgs);
  for (let index = 0; index < keys.length; index++) {
    const errorMsg = errorMsgs[keys[index]];
    if (errorMsg && errorMsg.indexOf("cannot exceed") !== -1) {
      return true;
    }
  }
  return false;
}
function extractValues(mappings) {
  return mappings.map(tmp => tmp.value);
}

const rightAlignedFields = [
  "scheduledLaborTimeInHours",
  "unscheduledLaborTimeInHours",
  "overridePrice",
  "priceAlacarte",
  "partsPrice",
  "partsPriceAlacarte",
  "totalPrice",
  "totalPriceAlacarte"
];
const validationFields = [
  "name",
  "dealerLaborRateCodeId",
  "vehicleCount",
  "scheduledLaborTimeInHours",
  "unscheduledLaborTimeInHours",
  "overridePrice",
  "priceAlacarte",
  "partsPrice",
  "partsPriceAlacarte",
  "totalPrice",
  "totalPriceAlacarte",
  "dmsOpcode"
];

const selectableFields = ["dealerLaborRateCodeId", "position"];

function getErrorMessages(errors, errorMsgs) {
  let errorSummary = "";
  const keys = Object.keys(errors);
  keys.forEach(key => {
    if (errors[key]) {
      if (errorMsgs[key]) {
        if (!errorSummary) {
          errorSummary = errorMsgs[key];
        } else {
          errorSummary = errorSummary.concat("\n\n").concat(errorMsgs[key]);
        }
      }
    }
  });
  return errorSummary;
}

// Simulate click function
function closeAddOverridePopover() {
  document.querySelector("#addOverrideBtn").click();
  // document.querySelector("#overrideGrid").click();
}
